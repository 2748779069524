import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import vectorGreaterSymbol from "../../../../assets/images/landingPage/vectorGreaterSymbol.svg";
import vectorLesserSymbol from "../../../../assets/images/landingPage/vectorLesserSymbol.svg";
import { validateUnileverMailId } from "../../../../utils";
import "./uniqueRPM.css";
import {
  saveUniqueRPMData,
  saveUniqueRPMRecipients,
  fetchProjectWorkFlowStatus,
} from "../../../../service/projectDetails";
import { Checkbox } from "@mui/material";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import UserDetailsService from "../../../../service/userDetailsService";
import UniqueRPMGrid from "./uniqueRPMGrid";
import ModalRNDSignOff from "../RNDSignOff/modalRNDSignOff";
import ModalWarning from "../modalWarning";

const UniqueRPMSelection = (props) => {
  const [isReceipientEnable, setIsReceipientEnable] = useState(
    props.isReceipientEnable
  );
  const [recipient, setRecipient] = useState("");
  const [message, setMessage] = useState("");
  const [isOkBtnDisabled, setIsOkBtnDisabled] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const {
    isEdit,
    objUniqueRPM,
    isLoading,
    setIsLoading,
    isDataNotAvailable,
    stepStatus,
  } = props;
  const [isAssignEnable, setIsAssignEnable] = useState(true);
  const [workflowData, setWorkflowData] = useState();
  const [showRDModal, setShowRDModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [isInventoryCMSkipped, setIsInventoryCMSkipped] = useState(
    props.arrItem.workflow[5].status === "6" ? true : false
  );
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);

  //Redux Start
  const dispatch = useDispatch();
  useEffect(() => {
    setRecipient("");
    setMessage("");
  }, [props.objUniqueRPM]);

  useEffect(() => {
    setIsReceipientEnable(props.isReceipientEnable);
  }, [props.isReceipientEnable]);

  useEffect(() => {
    if (objUniqueRPM && isReceipientEnable) {
      let isOkBtnEnable = true;
      objUniqueRPM.uniqueRMPMDetail.map((item, i) => {
        if (!item.recipientEmail || !item.mailMessage) {
          isOkBtnEnable = false;
        }
      });

      setIsOkBtnDisabled(!isOkBtnEnable);
    } else {
      if (objUniqueRPM) {
        setIsOkBtnDisabled(
          objUniqueRPM && objUniqueRPM.uniqueRMPMDetail ? false : true
        );
      }
    }
  }, [objUniqueRPM, isReceipientEnable]);

  const closeMessageModal = () => {
    setIsReceipientEnable(true);
    setShowMessageModal(false);
  };

  const onWarnModalOpen = (modalOpen) => {
    if (stepStatus !== "4") {
      setIsWarnWindowOpen(modalOpen);
    } else {
      onOkBtnClicked();
    }
  };

  const handleOkMessageModal = async () => {
    setIsLoading(true);
    const objUniqueRPMToReset = {
      ...objUniqueRPM,
      uniqueRMPMDetail: objUniqueRPM.uniqueRMPMDetail.map((item) => {
        const { isRowSelected, ...updatedItem } = item;
        return {
          ...updatedItem,
          recipientEmail: "",
          mailMessage: "",
          isRnDApproved: false,
          isSelected: false,
        };
      }),
    };

    const res = await saveUniqueRPMRecipients(objUniqueRPMToReset);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
    } else {
      console.log("error in reset Recipients", res.data);
    }
    setIsReceipientEnable(false);
    setShowMessageModal(false);
    props.updateGridData(true, "reset");
  };

  const handleAssignButton = async () => {
    setIsLoading(true);
    const objUniqueRPMToAssign = {
      ...objUniqueRPM,
      uniqueRMPMDetail: objUniqueRPM.uniqueRMPMDetail
        .filter((item) => item.isRowSelected)
        .map((item) => {
          const { isRowSelected, ...updatedItem } = item;
          return {
            ...updatedItem,
            recipientEmail: recipient,
            mailMessage: message,
            isRnDApproved: false,
            isSelected: false,
          };
        }),
    };

    const res = await saveUniqueRPMRecipients(objUniqueRPMToAssign);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
      setIsAssignEnable(true);
    } else {
      console.log("error in assign Recipients", res.data);
    }
    props.updateGridData(true, "assign");
  };

  const onOkBtnClicked = async (e) => {
    setIsOkBtnDisabled(true);
    setIsLoading(true);
    if (props.currentWorkflowStage === 4) {
      let objUniqueRPMToSave = Object.assign({}, objUniqueRPM);
      if (
        objUniqueRPMToSave.uniqueRMPMDetail &&
        objUniqueRPMToSave.uniqueRMPMDetail.length > 0
      ) {
        objUniqueRPMToSave.uniqueRMPMDetail = objUniqueRPM.uniqueRMPMDetail.map(
          (item) => {
            const { isRowSelected, ...updatedItem } = item;
            updatedItem.isRnDApproved = false;
            if (isReceipientEnable) {
              updatedItem.isSelected = false;
            }
            return updatedItem;
          }
        );
      } else {
        objUniqueRPMToSave.uniqueRMPMDetail = [];
      }
      objUniqueRPMToSave.userMailId = recipient === "" ? null : recipient;
      objUniqueRPMToSave.mailMessage = message === "" ? null : message;
      if (objUniqueRPM) {
        //objUniqueRPMToSave.piUser = `${userDetails.userFirstName}  ${userDetails.userLastName}`;
        objUniqueRPMToSave.validateProjectName = 1;
      } else {
        objUniqueRPMToSave.projectName = props.selectedProjectName;
        objUniqueRPMToSave.projectId = props.arrItem.projectId;
        objUniqueRPMToSave.projectIdDisplay = "";
        objUniqueRPMToSave.validateProjectName = 0;
      }
      const res = await saveUniqueRPMData(
        objUniqueRPMToSave,
        isReceipientEnable,
        isInventoryCMSkipped
      );
      //const res = await saveUniqueRPMData(objUniqueRPMToSave);
      if (res.status === 200) {
        localStorage.setItem("rdApproverName", recipient);
        localStorage.setItem("rdApproverMsg", message);
        const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
        if (res1.status === 200) {
          const res = {
            projectName: props.selectedProjectName,
            workflow: res1.data,
          };
          if (isReceipientEnable) {
            setWorkflowData(res);
            setShowRDModal(true);
          } else {
            dispatch(postUpdatedWorkFlowStatus(res));
            props.onComplete(false, false);
          }
          setWorkflowData(res);
        }
        setIsLoading(false);
      } else {
        setIsOkBtnDisabled(false);
        console.log(res.status);
      }
      props.updateGridData(true, "save");
    } else {
      setIsOkBtnDisabled(false);
      setIsLoading(false);
      props.onComplete(false, false);
    }
  };

  const handleRNDComplete = (isRNDApproved) => {
    if (isRNDApproved) {
      dispatch(postUpdatedWorkFlowStatus(workflowData));
      props.onComplete(false, false);
      props.updateGridData(true, "save");
    }
  };

  const enableMessageFacility = (e) => {
    setRecipient("");
    setMessage("");
    if (!isReceipientEnable) {
      setIsReceipientEnable(true);
    } else {
      setShowMessageModal(true);
    }
    setValidationMessage("");
  };
  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleRecipientValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        setValidationMessage("");
        getUserDetails(event.target.value);
      } else {
        setValidationMessage("Please provide valid Unilever Mail id");
      }
    }
  };
  const handleMessageValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      setMessage(event.target.value);
    }
  };

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setValidationMessage("");
      setRecipient(recepientMailId);
    } else {
      setValidationMessage("The unilever mail id does not exist");
    }
  };

  const selectionValueChange = (e) => {
    if (isReceipientEnable) {
      let isAnyRowSelected = false;

      const objUniqueRPMToSave = {
        ...objUniqueRPM,
        uniqueRMPMDetail: objUniqueRPM.uniqueRMPMDetail.filter(
          (item) => item.isRowSelected
        ),
      };

      if (objUniqueRPMToSave.uniqueRMPMDetail.length > 0) {
        let isSameMessage = true;
        let message = "";
        let recepientId = "";
        objUniqueRPMToSave.uniqueRMPMDetail.map((item, i) => {
          if (i === 0) {
            message = item.mailMessage;
          } else {
            if (
              message !== item.mailMessage &&
              recepientId !== item.recipientEmail
            ) {
              isSameMessage = false;
            }
          }
          recepientId = item.recipientEmail;
          message = item.mailMessage;
        });
        if (isSameMessage) {
          setRecipient(recepientId);
          setMessage(message);
        } else {
          setRecipient("");
          setMessage("");
        }
      } else {
        setRecipient("");
        setMessage("");
      }

      objUniqueRPM.uniqueRMPMDetail.map((item, i) => {
        if (item.isRowSelected) {
          isAnyRowSelected = true;
        }
      });
      setIsAssignEnable(!isAnyRowSelected);
      setValidationMessage("");
    }
  };

  return (
    <>
      <div className="bwi-modal-unique-rpm-body">
        <div className="bwi-unique-rpm-table">
          {!isReceipientEnable && (
            <Row className="bwi-rpm-grid-row bwi-unique-rpm-checkbox-info">
              <Col className="bwi-unique-rpm-header-checkbox" xs={1}>
                <Checkbox
                  disabled={true}
                  className="bwi-checkbox bwi-unique-rpm-legend-checkbox"
                  checked={false}
                />
                <span className="dsf-size11 dsf-font-weight-400 bwi-unique-rpm-legend-span">
                  Delisted
                </span>
              </Col>
              <Col
                className="bwi-unique-rpm-header-checkbox"
                xs={3}
                style={{ display: "flex" }}
              >
                <Checkbox
                  disabled={true}
                  className="bwi-checkbox bwi-unique-rpm-legend-checkbox"
                  checked={true}
                />
                <span
                  className="dsf-size11 dsf-font-weight-400 bwi-unique-rpm-legend-span"
                  style={{ paddingTop: "5px" }}
                >
                  Not Delisted - Materials to be kept/reused
                </span>
              </Col>
            </Row>
          )}
          {
            <UniqueRPMGrid
              objUniqueRPM={objUniqueRPM}
              isLoading={isLoading}
              isEdit={isEdit}
              isDataNotAvailable={isDataNotAvailable}
              isReceipientEnable={isReceipientEnable}
              isRNDPIUser={false}
              isCheckboxDisabled={false}
              selectionValueChange={selectionValueChange}
            />
          }
        </div>
        <Row
          className="bwi-rpm-grid-row bwi-rpm-grid-recipient-enable"
          style={{ marginTop: "8px" }}
        >
          <Col className="bwi-rpm-grid-header-checkbox">
            <Checkbox
              checked={isReceipientEnable}
              disabled={!isEdit || isDataNotAvailable}
              onChange={(e) => enableMessageFacility(e)}
              className="bwi-checkbox"
              disableRipple
            />
            <span className="dsf-size12 dsf-font-weight-600 bwi-uniqueRPM-span">
              Check this box if this action requires sign off from R&D
            </span>
          </Col>
        </Row>
        {isReceipientEnable && (
          <div className="bwi-rpm-grid-mail-data">
            <Row
              className="bwi-rpm-grid-mail-row1"
              style={{ marginTop: "5px" }}
            >
              <Col className="bwi-rpm-grid-textBox-label1">
                <label>Recipient</label>
              </Col>
              <Col className="bwi-rpm-grid-textBox-label2">
                <label style={{ marginLeft: "9px" }}>Message</label>
              </Col>
            </Row>
            <Row className="" style={{ marginLeft: "0px" }}>
              <Col className="bwi-rpm-grid-textBox-div1">
                <Row>
                  <input
                    type="text"
                    name="recipient"
                    value={recipient}
                    className={`bwi-rpm-grid-textField  ${
                      validationMessage.length > 0
                        ? "errorBorder"
                        : "successBorder"
                    }`}
                    disabled={!isReceipientEnable || isAssignEnable}
                    onChange={(e) => handleRecipientChange(e)}
                    onBlur={(e) => handleRecipientValueOnEnter(e)}
                    onKeyDown={(e) => handleRecipientValueOnEnter(e)}
                  />
                </Row>
                <Row>
                  <span className="dsf-size11 dsf-font-weight-400 errorMsg">
                    {validationMessage}
                  </span>
                </Row>
              </Col>
              <Col className="bwi-rpm-grid-textBox-div2">
                <input
                  type="text"
                  name="message"
                  value={message}
                  className="bwi-rpm-grid-textField"
                  disabled={!isReceipientEnable || isAssignEnable}
                  onChange={(e) => handleMessageChange(e)}
                  onBlur={(e) => handleMessageValueOnEnter(e)}
                  onKeyDown={(e) => handleMessageValueOnEnter(e)}
                />
              </Col>
              <Col className="bwi-rpm-grid-textBox-div3">
                <button
                  className="bwi-unique-rpm-assign-btn"
                  disabled={
                    message.length === 0 ||
                    recipient.length === 0 ||
                    validationMessage.length > 0
                  }
                  onClick={() => handleAssignButton()}
                >
                  Assign
                </button>
              </Col>
            </Row>
          </div>
        )}
        <div className="bwi-skipInventory-div">
          <Row className="bwi-rpm-grid-row bwi-rpm-grid-recipient-enable">
            <Col className="bwi-rpm-grid-header-checkbox">
              <Checkbox
                checked={isInventoryCMSkipped}
                disabled={!isEdit}
                onChange={() => setIsInventoryCMSkipped(!isInventoryCMSkipped)}
                className="bwi-checkbox"
                disableRipple
              />
              <span className="dsf-size12 dsf-font-weight-600">
                Check this box to skip input supplier stock step.
              </span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="bwi-modal-unique-rpm-footer">
        <div className="bwi-footer-prevbtn-div">
          {/* <Button
            className="bwi-footer-button-movement bwi-footer-button-prev bwi-unique-rpm-footer-btn"
            variant="secondary"
            onClick={() => {
              props.onComplete(true, "Previous");
            }}
          >
            <img
              src={vectorLesserSymbol}
              alt="Next"
              style={{ marginRight: "8px", paddingTop: "2px" }}
            />{" "}
            Previous
          </Button> */}
        </div>
        <div className="bwi-modal-footer">
          <Button
            className="bwi-unique-rpm-footer-btn bwi-submit-button"
            variant="secondary"
            disabled={!isEdit || isOkBtnDisabled}
            onClick={() => onWarnModalOpen(true)}
          >
            Submit
          </Button>
          {/* <Button
            className={`bwi-footer-button-movement bwi-footer-button-next bwi-unique-rpm-footer-btn ${
              props.arrItem.workflow[5].status === "1" &&
              props.arrItem.workflow[7].status === "1" &&
              "dsf-cursor-disabled"
            }`}
            variant="secondary"
            disabled={
              props.arrItem.workflow[5].status === "1" &&
              props.arrItem.workflow[7].status === "1"
            }
            onClick={() => {
              props.onComplete(true, "Next");
            }}
          >
            Next{" "}
            <img
              src={vectorGreaterSymbol}
              alt="Next"
              style={{ marginLeft: "8px", paddingTop: "2px" }}
            />
          </Button> */}
        </div>
      </div>

      {showRDModal && (
        <ModalRNDSignOff
          showModal={true}
          setShowModal={(isModalOpen) => setShowRDModal(isModalOpen)}
          onComplete={handleRNDComplete}
        />
      )}

      <Modal
        centered
        dialogClassName="dsf-dialog-messagebox dsf-delistingProj-okdialog dsf-rpm-messagebox"
        contentClassName="dsf-modal-messagebox dsf-delistingProj-okmodal dsf-rpm-messagebox-modal"
        show={showMessageModal}
      >
        <Modal.Header className="bw-modal-messagebox-header">
          <Modal.Title className="dsf-size16 bw-messagebox-header-title dsf-title-color">
            Please Confirm
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close-Image"
            className="img.dsf-close-messagebox-img"
            onClick={() => closeMessageModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="bw-modal-messagebox-body">
          <p className="bw-modal-messagebox-msg">
            Message sent to R&D to validate RPM materials to be reused in new
            SKU.
          </p>
          <p className="bw-modal-messagebox-msg">
            You can proceed to the next step whilst waiting for sign off
          </p>
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer">
          <Button
            className="dsf-footer-okbutton-PI"
            variant="secondary"
            onClick={() => handleOkMessageModal()}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={onOkBtnClicked}
          onWarnModal={onWarnModalOpen}
        />
      )}
    </>
  );
};
export default UniqueRPMSelection;
