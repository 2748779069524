import React, { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";
import { formatNumber } from "../utils";

const StackedHorizontalBarChartDesign = (props) => {
  const [barChartData, setBarChartData] = useState([]);
  useEffect(() => {
    let arrBarChartData = [],
      annotation;
    props.chartData &&
      props.chartData.map((item, index) => {
        annotation = formatNumber(
          parseFloat(item.fgbw) + parseFloat(item.rmbw) + parseFloat(item.pmbw)
        );
        arrBarChartData.push([
          item.uom,
          Math.round(parseFloat(item.fgbw)),
          Math.round(parseFloat(item.rmbw)),
          Math.round(parseFloat(item.pmbw)),
          Math.round(parseFloat(item.sfgbw)),
          `${annotation} ${item.uom}`,
        ]);
      });
    setBarChartData(arrBarChartData);
  }, [props.chartData]);

  useEffect(() => {
    GoogleCharts.load(drawMultSeries, {
      packages: ["corechart", "bar"],
    });
  }, [barChartData]);

  const drawMultSeries = () => {
    let data_n = new GoogleCharts.api.visualization.DataTable();
    data_n.addColumn("string", "Asset name");
    data_n.addColumn("number", "Finished Goods");
    data_n.addColumn("number", "Raw Materials");
    data_n.addColumn("number", "Pack Materials");
    data_n.addColumn("number", "HALB Materials");
    data_n.addColumn({ type: "string", role: "annotation" });
    data_n.addRows(barChartData);
    var chartHeight =
      data_n.getNumberOfRows() <= 3
        ? data_n.getNumberOfRows() * 40
        : data_n.getNumberOfRows() * 40 - data_n.getNumberOfRows() * 10;
    var rowHeight = data_n.getNumberOfRows() * 30;
    const options = {
      height: chartHeight,
      legend: { position: "left" },
      bar: { groupWidth: "20" },
      backgroundColor: "transparent",
      chartArea: { width: "50%", height: rowHeight, left: "20%" },
      isStacked: true,
      hAxis: {
        gridlines: {
          count: 0,
          color: "#FFF",
          type: "dotted",
        },
        titleTextStyle: { color: "#FFF" },
        textStyle: { color: "#FFF", fontSize: 12 },
        textPosition: "none",
      },
      vAxis: {
        titleTextStyle: { color: "#EDF1F3" },
        textStyle: { color: "#EDF1F3", fontSize: 12 },
      },
      series: {
        0: { color: "#8FCAEB" },
        1: { color: "#FCEDA4" },
        2: { color: "#C79AD5" },
        3: { color: "#CFCFCF" },
      },
      annotations: {
        stemColor: "none",
        alwaysOutside: true,
        textStyle: {
          fontSize: 12,
          bold: true,
          color: "#797775",
        },
      },
    };
    let container = document.getElementById(props.container);
    let chart = new GoogleCharts.api.visualization.BarChart(container);
    chart.draw(data_n, options);
    const labels = container.getElementsByTagName("text");
    Array.prototype.forEach.call(labels, function (label, index) {
      if (label.getAttribute("text-anchor") === "end") {
      }
    });
    new GoogleCharts.api.visualization.events.addListener(
      chart,
      "select",
      function () {
        var selection = chart.getSelection();
        let selectedIndex;
        selection.map((item) => {
          selectedIndex = item.row;
        });
        props.onChange(selectedIndex);
      }
    );
    chart.draw(data_n, options);
  };
};

export default StackedHorizontalBarChartDesign;
