import { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";

const ComboChartDesign = (props) => {
  const {
    container,
    chartData,
    barColor,
    chartAreaLeft,
    chartAreaBottom,
    chartAreaWidth,
    chartAreaHeight,
    chartWidth,
    chartHeight,
    barCount,
  } = props;
  const [barChartData, setbarChartData] = useState([]);
  const [ticks, setTicks] = useState([]);

  useEffect(() => {
    let arrChartData = [];
    if (chartData) {
      const total = chartData.reduce((acc, item) => acc + item.value, 0);
      let cumulative = 0;
      chartData.forEach((item, index) => {
        cumulative += item.value;
        const cumulativePercentage = (cumulative / total) * 100;
        arrChartData.push([
          item.code,
         item.materialType !== 'SFG' ? item.value : item.value,
          "color: " + (item.materialType !== 'SFG' ? barColor : "#CFCFCF"),
          cumulativePercentage,
        ]);
      });
    }

    setbarChartData(arrChartData);
  }, [props.chartData]);

  useEffect(() => {
    GoogleCharts.load(drawChart, { packages: ["corechart"] });
  }, [barChartData]);

  const drawChart = () => {
    const chartDiv = document.getElementById(container);
    let data = new GoogleCharts.api.visualization.DataTable();
    data.addColumn("string", "code");
    data.addColumn("number", "Value");
    data.addColumn({ type: "string", role: "style" });
    data.addColumn("number", "Cumulative Percentage");
    data.addRows(barChartData);
    const rowWidth =
      data.getNumberOfRows() > barCount
        ? data.getNumberOfRows() * 40
        : chartAreaWidth;
    const width =
      data.getNumberOfRows() > barCount
        ? data.getNumberOfRows() * 40
        : chartAreaWidth;
    const left = data.getNumberOfRows() > barCount ? 50 : chartAreaLeft;

    var options = {
      width: width,
      height: chartHeight,
      backgroundColor: "transparent",
      chartArea: {
        left: left,
        bottom: chartAreaBottom,
        width: rowWidth,
        height: chartAreaHeight,
        right: 40,
      },
      seriesType: "bars",
      series: {
        0: { 
          type: "bars",
          targetAxisIndex: 0,
         },
        1: {
          type: "line",
          targetAxisIndex: 1,
          curveType: "function",
          color: "#EC6157",
        },
      },
      legend: "none",
      bar: {
        groupWidth: "20",
      },
      vAxes: {
        0: {
          textStyle: {
            color: "#edf1f3",
            fontSize: 8,
            fontFamily: "Unilever Shilling",
            fontWeight: 400,
          },
          gridlines: {
            color: "#797775",
            count: -1,
          },
          baselineColor: "#797775",
        },
        1: {
          minValue: 0,
          maxValue: 100,
          textStyle: {
            color: "#edf1f3",
            fontSize: 8,
            fontFamily: "Unilever Shilling",
            fontWeight: 400,
          },
          gridlines: {
            color: "#797775",
            count: -1,
          },
          baselineColor: "#797775",
          viewWindow: {
            max: 100,
            min: 0,
          },
          format: "#'%'",
        },
      },
      hAxis: {
        textStyle: {
          color: "#edf1f3",
          fontSize: 8,
          fontFamily: "Unilever Shilling",
          fontWeight: 400,
        },
        slantedText: true,
        slantedTextAngle: 26,
      },
      animation: {
        duration: 500,
        easing: "in",
        startup: true,
      },
      tooltip: {
        textStyle: {
          fontSize: 10,
        },
      },
    };

    const chart = new GoogleCharts.api.visualization.ComboChart(chartDiv);
    new GoogleCharts.api.visualization.events.addListener(
      chart,
      "select",
      function () {
        var selection = chart.getSelection();
        let selectedIndex;
        selection.map((item) => {
          selectedIndex = item.row;
        });
        props.onChange(selectedIndex);
        if (selection.length === 1) {
          var item = selection[0];
          if (item.row != null && item.column != null) {
            var str = data.getFormattedValue(item.row, item.column);
          }
        }
      }
    );
    chart.draw(data, options);
  };
};
export default ComboChartDesign;
